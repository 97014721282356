<template>
  <div class="contas">
    <h1 class="titulo-card fs-4">Contas bancárias / {{tituloAcao}}</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
      </div>
      <div v-if="tituloAcao" class="form-group">
        <div class="row">
          <div class="col-12">
            <label class="custom-control custom-checkbox">
              <input type="checkbox" v-model="modalConta.data.active" class="custom-control-input">
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description"> Ativo</span>
            </label>
          </div>
          <div class="col-12 col-sm-4 col-md-3">
            <label class="required">Tipo de cadastro</label>
            <select v-model="modalConta.data.type" v-bind:disabled="!modoCadastro()" class="form-select">
              <option v-bind:value="undefined" disabled>Selecione</option>
              <option value="asaas">Asaas</option>
              <option value="gerencianet">Gerencianet</option>
            </select>
          </div>
          <div class="col-12 col-sm-8 col-md-6">
            <label class="required">Descrição</label>
            <input type="text" v-model="modalConta.data.description" class="form-control">
          </div>
          <div class="col-12 col-sm-12 col-md-3">
            <label class="required">Conta destino</label>
            <select v-model="modalConta.data.walletId" class="form-select">
              <option v-bind:value="undefined" disabled>Selecione</option>
              <option v-for="wallet in wallets" :key="wallet.id" :value="wallet.id">{{wallet.description}}</option>
            </select>
          </div>
          <div class="col-12">
            <label class="required">Emitente (para boleto bancário)</label>
            <input type="text" v-model="modalConta.data.emitterName" class="form-control">
          </div>
        </div>

        <!-- config asaas -->
        <div v-if="modalConta.data.type === 'asaas'" class="row mt-2">
          <h5 class="text-info">Configuração Asaas</h5>
          <div class="col-12 col-sm-6">
            <label class="required">Endpoint</label>
            <select v-model="modalConta.configAsaas.endpoint" class="form-select">
              <option value="" disabled>Selecione</option>
              <option value="https://sandbox.asaas.com">Sandbox</option>
              <option value="https://www.asaas.com">Produção</option>
            </select>
          </div>
          <div class="col-12 col-sm-6">
            <label class="required">Token</label>
            <input type="text" v-model="modalConta.configAsaas.token" class="form-control">
          </div>
        </div>
        <!-- config asaas -->

        <!-- config gerencianet -->
        <div v-if="modalConta.data.type === 'gerencianet'" class="row mt-2">
          <h5 class="text-info">Configuração Gerencianet</h5>
          <div class="col-12 col-sm-6">
            <label class="required">Endpoint</label>
            <select v-model="modalConta.configGerencianet.endpoint" class="form-select">
              <option value="" disabled>Selecione</option>
              <option value="https://sandbox.gerencianet.com.br">Sandbox</option>
              <option value="https://api.gerencianet.com.br">Produção</option>
            </select>
          </div>
          <div class="col-12 col-sm-6">
            <label class="required">Client-ID</label>
            <input type="text" v-model="modalConta.configGerencianet.clientId" class="form-control">
          </div>
          <div class="col-12 col-sm-6">
            <label class="required">Client-Secret</label>
            <input type="text" v-model="modalConta.configGerencianet.clientSecret" class="form-control">
          </div>
        </div>
        <!-- config gerencianet -->

        <div class="d-flex justify-content-end py-2">
          <button type="button" v-if="modalConta.data.id" @click="handleRemoveConta()" class="btn btn-outline-danger me-2">
            <font-awesome-icon :icon="['fas', 'trash']"/>
            Excluir
          </button>
          <button type="button"
              v-if="!modalConta.data.id"
              v-on:click="handleModalContaCadastrar()"
              v-bind:disabled="!podeSalvarConta()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']"/>
            Cadastrar
          </button>
          <button type="button"
              v-if="!!modalConta.data.id"
              v-on:click="handleModalContaSalvar()"
              v-bind:disabled="!podeSalvarConta()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']"/>
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'

export default {
  name: 'BanksEdit',
  components: {
  },
  data() {
    return {
      tituloAcao: '',
      contaID: undefined,
      listaContas: [],
      wallets: [],
      modalConta: {
        data: {
          id: undefined,
          active: true,
          type: undefined,
          walletId: undefined,
          description: '',
          configAsaas: undefined,
          configGerencianet: undefined,
        },
        configAsaas: {
          endpoint: '',
          token: '',
        },
        configGerencianet: {
          endpoint: '',
          clientId: '',
          clientSecret: '',
        },
      }
    }
  },
  methods: {
    initNewCustomer() {
      this.tituloAcao = 'Cadastrar';
      this.resetModalConta();
      this.loadWallets();
    },

    initEditCustomer() {
      this.tituloAcao = 'Editar';
      this.loadWallets();

      api.get(`/banks/${this.contaID}`).then(res => {
        loading(false);
        this.modalConta.data = res.data;
        switch (this.modalConta.data.type) {
          case 'asaas':
            this.modalConta.configAsaas = res.data.configAsaas;
            break;

          case 'gerencianet':
            this.modalConta.configGerencianet = res.data.configGerencianet;
            break;
        }
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    loadWallets() {
       api.get(`/wallets?paginate.cancel&active=true`).then(res => {
        loading(false);
        this.wallets = res.data.items;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleAdicionar() {
      this.abreModalCadastroConta();
    },

    async handleEditaConta(conta) {
      this.resetModalConta();

      this.modalConta.data = clone(conta);

      document.getElementById('btn-modal-edita-conta-abre').click();
    },

    handleRemoveConta() {
      dialogo.confirmacao(`Confirma remover o conta <b>${this.modalConta.data.description}</b>?`).then(() => {
        loading(true);

        api.delete(`/banks/${this.modalConta.data.id}`).then(() => {
          loading(false);

          this.$router.push('/configuracoes/contas-bancarias');
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    abreModalCadastroConta() {
      this.resetModalConta();
      document.getElementById('btn-modal-edita-conta-abre').click();
    },

    resetModalConta() {
      this.modalConta.data.id = undefined;
      this.modalConta.data.active = true;
      this.modalConta.data.type = undefined;
      this.modalConta.data.description = '';
      this.modalConta.data.walletId = undefined;
      this.modalConta.data.configAsaas = undefined;
      this.modalConta.data.configGerencianet = undefined;
      this.modalConta.configAsaas.endpoint = '';
      this.modalConta.configAsaas.token = '';
      this.modalConta.configGerencianet.endpoint = '';
      this.modalConta.configGerencianet.clientId = '';
      this.modalConta.configGerencianet.clientSecret = '';
    },

    podeSalvarConta() {
      switch (true) {
        case (this.modalConta.data.description.length < 3):
        case (!this.modalConta.data.type):
        case (!this.modalConta.data.walletId):
          return false;

        default:
          return true;
      }
    },

    handleModalContaCadastrar() {
      const sendData = {...this.modalConta.data};
      switch (sendData.type) {
        case 'asaas':
          sendData.configAsaas = this.modalConta.configAsaas;
          break;

        case 'gerencianet':
          sendData.configGerencianet = this.modalConta.configGerencianet;
          break;
      }

      loading(true);
      api.post('/banks', sendData).then(() => {
        loading(false);
        this.$router.push('/configuracoes/contas-bancarias');
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleModalContaSalvar() {
      const sendData = {...this.modalConta.data};

      loading(true);
      api.put(`/banks`, sendData).then(() => {
        loading(false);
        this.$router.push('/configuracoes/contas-bancarias');
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    modoCadastro() {
      return this.$route.name === 'BanksNew';
    },
  },
  created() {
    switch (this.$route.name) {
      case 'BanksNew':
        this.initNewCustomer();
        break;

      default: {
        this.contaID = this.$route.params.contaID;
        if (!this.contaID) {
          this.$router.push({ path: `/configuracoes/contas-bancarias` });
          return;
        }

        this.initEditCustomer();
      }
    }
  }
}
</script>
